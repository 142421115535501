/* eslint-disable react/no-danger */
import { h } from 'preact';

import { translate } from '../../../../common/intl';

import style from './style.scss';

const TcParagraph = () => (
  <div className={style.container}>
    <p>
      <span dangerouslySetInnerHTML={{ __html: translate('I18N_TERMS_MODAL_BY_PROCEEDING', '<p style="text-align: justify">By proceeding, you accept the AMPLIFiCAM <a href="https://www.amplificam.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://www.amplificam.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Service</a>.</p>') }} />
    </p>
  </div>
);

export default TcParagraph;
